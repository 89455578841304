// BatchReportDialog.tsx - Read-only dialog for displaying batch report details

import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton, Button, Box, Typography, Paper,
    Table, TableHead, TableBody, TableRow, TableCell,
    useMediaQuery, Theme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Define the structure of a batch report item
interface BatchReportItem {
    DATE: string | null;
    PRODUCT: string;
    INGREDIENT: string;
    "INGREDIENT LOT CODE": string;
    "PRODUCT BATCH #": number;
    QUANTITY: string;
    "EXP. DATE": string | null;
    "Pre-Inspection": string;
    "Post-Inspection": string;
}

interface BatchReportDialogProps {
    open: boolean;
    onClose: () => void;
    report?: BatchReportItem | null;
    allReports: BatchReportItem[];
}

const BatchReportDialog: React.FC<BatchReportDialogProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 report,
                                                                 allReports
                                                             }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    // Determine which reports to display
    const reportsToDisplay = report ? [report] : allReports;

    // Format date for display
    const formatDate = (dateString: string | null) => {
        if (!dateString) return '-';
        return new Date(dateString).toLocaleDateString();
    };

    // Style for table cells
    const cellStyle = {
        border: '1px solid black',
        padding: '8px',
        textAlign: 'center' as 'center',
        fontSize: '14px',
    };

    // Header cell style
    const headerCellStyle = {
        ...cellStyle,
        backgroundColor: '#f2f2f2',
        fontWeight: 'bold',
        fontSize: '14px',
    };

    // Mobile view - Card layout for each report
    const MobileView = () => (
        <Box sx={{ mb: 3 }}>
            {reportsToDisplay.map((item, index) => (
                <Paper key={index} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" align="center" sx={{ mb: 2, bgcolor: 'action.hover', py: 1, borderRadius: 1 }}>
                        ALFRED'S VEGAN BATCH PRODUCTION LOG
                    </Typography>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Date:</Typography>
                        <Typography>{formatDate(item.DATE)}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Product:</Typography>
                        <Typography>{item.PRODUCT}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Ingredient:</Typography>
                        <Typography>{item.INGREDIENT}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Batch #:</Typography>
                        <Typography>{item["INGREDIENT LOT CODE"]}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Product Batch #:</Typography>
                        <Typography>{item["PRODUCT BATCH #"]}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Quantity:</Typography>
                        <Typography>{item.QUANTITY}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Exp. Date:</Typography>
                        <Typography>{formatDate(item["EXP. DATE"])}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Pre-Inspection:</Typography>
                        <Typography>{item["Pre-Inspection"] || '-'}</Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2">Post-Inspection:</Typography>
                        <Typography>{item["Post-Inspection"] || '-'}</Typography>
                    </Box>
                </Paper>
            ))}
        </Box>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            fullScreen={isMobile}
            PaperProps={{
                sx: {
                    '@media print': {
                        width: '100%',
                        maxWidth: 'none',
                        height: 'auto',
                        margin: 0,
                        boxShadow: 'none'
                    }
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '@media print': { display: 'none' }
            }}>
                <span>{report ? `Batch #${report["PRODUCT BATCH #"]}` : "All Batch Reports"}</span>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        '@media print': { display: 'none' }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
                '@media print': {
                    padding: { xs: '5mm', sm: '5mm', md: '5mm' },
                    width: '100%',
                    maxWidth: 'none',
                    overflow: 'visible'
                }
            }}>
                {isMobile ? (
                    <MobileView />
                ) : (
                    <>
                        {/* Title */}
                        <Box sx={{
                            width: '100%',
                            textAlign: 'center',
                            mb: 3
                        }}>
                            <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold' }}>
                                ALFRED'S VEGAN BATCH PRODUCTION LOG
                            </Typography>
                        </Box>

                        {/* Report Table */}
                        <Table sx={{
                            border: '2px solid black',
                            borderCollapse: 'collapse',
                            width: '100%'
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCellStyle}>DATE</TableCell>
                                    <TableCell sx={headerCellStyle}>PRODUCT</TableCell>
                                    <TableCell sx={headerCellStyle}>INGREDIENT</TableCell>
                                    <TableCell sx={headerCellStyle}>BATCH #</TableCell>
                                    <TableCell sx={headerCellStyle}>PRODUCT BATCH #</TableCell>
                                    <TableCell sx={headerCellStyle}>QUANTITY</TableCell>
                                    <TableCell sx={headerCellStyle}>EXP. DATE</TableCell>
                                    <TableCell sx={headerCellStyle}>Pre-Inspection</TableCell>
                                    <TableCell sx={headerCellStyle}>Post-Inspection</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportsToDisplay.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={cellStyle}>{formatDate(item.DATE)}</TableCell>
                                        <TableCell sx={cellStyle}>{item.PRODUCT}</TableCell>
                                        <TableCell sx={cellStyle}>{item.INGREDIENT}</TableCell>
                                        <TableCell sx={cellStyle}>{item["INGREDIENT LOT CODE"]}</TableCell>
                                        <TableCell sx={cellStyle}>{item["PRODUCT BATCH #"]}</TableCell>
                                        <TableCell sx={cellStyle}>{item.QUANTITY}</TableCell>
                                        <TableCell sx={cellStyle}>{formatDate(item["EXP. DATE"])}</TableCell>
                                        <TableCell sx={cellStyle}>{item["Pre-Inspection"] || '-'}</TableCell>
                                        <TableCell sx={cellStyle}>{item["Post-Inspection"] || '-'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{ '@media print': { display: 'none' } }}>
                <Button onClick={onClose} color="inherit">
                    Close
                </Button>
                <Button
                    onClick={() => {
                        // Add styling for printing
                        const style = document.createElement('style');
                        style.innerHTML = `
                            @page {
                                size: landscape;
                                margin: 0; /* No margin for maximum space */
                            }
                            @media print {
                                body, html {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    overflow: hidden !important;
                                }
                                /* Make content fill almost the entire page */
                                .MuiDialog-paper {
                                    width: 100% !important;
                                    max-width: none !important;
                                    margin: 0 !important;
                                    max-height: 100% !important; /* Ensure it fits on one page */
                                    page-break-after: avoid !important; /* Prevent page break */
                                    overflow: hidden !important;
                                }
                                .MuiDialogContent-root {
                                    width: 100% !important;
                                    padding: 2mm !important;
                                    height: 100% !important;
                                    max-height: 100% !important;
                                    overflow: hidden !important;
                                }
                                table {
                                    width: 100% !important;
                                    max-width: none !important;
                                    height: 70vh !important; /* Force table to take most of page height */
                                    page-break-inside: avoid !important; /* Prevent table from breaking across pages */
                                }
                                
                                /* Font size adjustments - MUCH larger */
                                th, td {
                                    font-size: 18pt !important;
                                    padding: 10px !important;
                                    text-align: center !important;
                                    vertical-align: middle !important;
                                }
                                
                                /* Make header rows taller */
                                thead tr {
                                    height: 80px !important; /* Taller header height */
                                }
                                th {
                                   line-height: 1.2 !important;
                                   font-weight: bold !important;
                                   background-color: #f2f2f2 !important;
                                }
                                
                                /* Increase row height to fill page */
                                tbody tr {
                                    height: 60px !important;
                                }
                                
                                /* Make the entire form larger */
                                .MuiTypography-root {
                                    font-size: 24pt !important;
                                }
                                h1, h5 {
                                    font-size: 36pt !important;
                                    margin-bottom: 15pt !important;
                                    font-weight: bold !important;
                                    text-align: center !important;
                                }
                                
                                /* Hide dialog buttons when printing */
                                .MuiDialogActions-root {
                                    display: none !important;
                                }
                                
                                /* Eliminate all possible sources of page breaks */
                                * {
                                    page-break-inside: avoid !important;
                                    page-break-after: avoid !important;
                                    page-break-before: avoid !important;
                                }
                            }
                        `;
                        document.head.appendChild(style);
                        window.print();
                        // Clean up after print dialog closes
                        setTimeout(() => {
                            document.head.removeChild(style);
                        }, 1000);
                    }}
                    variant="outlined"
                >
                    Print
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BatchReportDialog;