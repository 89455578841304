import React from 'react';
import {
    Ticket,
    MessageSquareMore,
    CreditCard,
    ClipboardCheck,
    Camera,
    LogIn,
    LogOut,
    List,
    Locate,
    Package,
    BookOpen,
    BarChart3,
    Truck,
    ListChecks,
    FileText,
    Factory,
    Settings,
    UserCog,
    ChevronRight,
    Info,
    Library,
    Users,
    HelpCircle,
    Apple,
    Utensils,
    AlertTriangle,
    Network,
    AlertOctagon,
    Boxes,
    Building,
    Box,
    Store,
    Wrench,
    Wheat,
    ClipboardList,
    ListOrdered,
    Package2,
    Tags,
    ShoppingBag,
    Bug,
    FileStack,
    CalendarDays,
    Receipt,
    Waypoints, Home,
} from 'lucide-react';

import PanelOne from './PanelOne';
import PanelTwo from "./PanelTwo";
import PanelThree from './Batch/PanelThree';
import TablePanel from './TablePanel';
import MarkdownDisplay from "./MarkdownDisplay";
import tableConfig from "./tableConfig";
import ScheduledProcessTable from "./ScheduledProcess/ScheduledProcessTable";
import AccountInfo from "./AccountInfo";
import Recall from "./Recall";
import Shipping from "./Shipping/Shipping";
import InventoryManagement from "./Inventory";
import PhotoCaptureUpload from "./PhotoCapture";
import FlowChartMenu from "./FlowChartMenu";
import { chart as lotCodeChart } from "./LotCodeChartDef";
import { chart as productionChart } from "./ProductionFlowChartDef";
import InventoryMenu from "./InventoryMenu";
import InMenu from "./InMenu";
import PhotoMenu from "./Photos/PhotoMenu";
import TablePanelPlus from "./TablePanelPlus";
import TablePanelFiltered from "./TablePanelFiltered";
import BatchesByDate from "./BatchesByDate";
import InvoiceTable from "./InvoiceTable";
import CatalogPanel from "./CatalogPanel";
import PestControl from "./PestControl/PestControl";
import About from "./About";
import TablePanelPlusPhotos from "./TablePanelPlusPhotos";
import SubMenu from "./SubMenu";
import ChatApp from "./Chat/ChatApp";
import TablePanelNew from "./TIckets/TablePanelNew";
import Blank from "./Blank";
import DynamicComponentLoader from "./LinkMenu/DynamicComponentLoader";
import {Test} from "./Test";
import EquipmentTable from "./EquipmentInspection/EquipmentTable";
import Catalog from "./Catalog/Catalog";
import BatchReport from "./OrganicAudit/BatchReport";

export interface MenuItem {
    icon?: React.ReactElement
    title: string
    component: React.ReactElement
    subItems?: MenuItem[]
    isHome?: boolean
}

const inspectionItems = [
    {name: 'Routine Inspections', label: 'Routine Inspections', component: <PanelOne/>},
    {name: 'Equipment Pre Check', label: 'Pre Operational Cleaning Check Log', component: <EquipmentTable endpoints={tableConfig.equipmentForms.endpoints}/>},
]

const auditItems = [
    {name: 'test', label: 'Receiving Log', component: <Test/>},
    {name: 'xxx', label: 'Customer Complaint Log', component: <Test/>},
    {name: 'Batch Report', label: 'Batch Production Log', component: <BatchReport/>},
    {name: 'Equipment Check', label: 'Pre Operational Cleaning Check Log ', component: <EquipmentTable endpoints={tableConfig.equipmentForms.endpoints}/>},
]

const outItems = [
    {name: 'Product Catalog', label: 'Catalog', component: <Catalog/>},
    {name: 'Ordering Catalog', label: 'Ordering Catalog', component: <Catalog ordering={true}/>},
    {name: 'Client Sales Sheet', label: 'Client Sales Sheet', component: <CatalogPanel/>},
    {name: 'Distributor Shipment', label: 'Distributor Shipment', component: <Shipping/>},
]

const getMenuItems = (handleNodeClick: (nodeName: string) => void, handleMenuAction: (action: string) => void): MenuItem[] => [
    {
        icon: <Home className="h-6 w-6" />,
        title: "Home",
        component: <div />,  // Empty div since the main screen handling is done in App.tsx
        isHome: true  // Add this flag to identify the home button
    },
    { icon: <ClipboardCheck className="h-6 w-6" />, title: "Inspections", component: <DynamicComponentLoader title="Inspections" componentsData={inspectionItems} /> },
    { icon: <Camera className="h-6 w-6" />, title: "Photos", component: <PhotoMenu onAction={handleMenuAction}/> },
    { icon: <LogIn className="h-6 w-6" />, title: "In",
        component: <SubMenu
            itemTitles={[
                'Visitors',
                'Deliveries',
                'Ingredient Receiving',
                'Packaging Receiving'
            ]}
            onAction={handleMenuAction}
        /> },
    { icon: <LogOut className="h-6 w-6" />, title: "Out", component: <DynamicComponentLoader title="Out" componentsData={outItems} /> },
    { icon: <List className="h-6 w-6" />, title: "Batches", component: <PanelThree /> },
    { icon: <Package className="h-6 w-6" />, title: "Inventory", component: <SubMenu
        itemTitles={[
        'Ingredients Inventory',
        'Product Inventory'
       ]}
    onAction={handleMenuAction}
    />
    },
    { icon: <BookOpen className="h-6 w-6" />, title: "Logs", component: <TablePanel byClient={true} columns={tableConfig.logs.columns} itemTitle="Log" endpoints={tableConfig.logs.endpoints} showTimestamp={true} /> },
    { icon: <BarChart3 className="h-6 w-6" />, title: "Review",component: <SubMenu
        itemTitles={[
            'Review Inspections',
            'Batches By Date'
        ]}
        onAction={handleMenuAction}
    />
    },
    { icon: <Ticket className="h-6 w-6" />, title: "Tickets", component: <TablePanelNew columns={tableConfig.tickets.columns} client="facility" itemTitle="Ticket" endpoints={tableConfig.tickets.endpoints} showTimestamp={true} /> },
    { icon: <MessageSquareMore className="h-6 w-6" />, title: "Chat", component: <ChatApp /> },
    { icon: <CreditCard className="h-6 w-6" />, title: "Cards", component: <Blank /> },
    { icon: <Locate className="h-6 w-6" />, title: "Planning",
        component: <SubMenu
            itemTitles={[
                'Projects',
                'Batches By Date',
            ]}
            onAction={handleMenuAction}
        /> },


    /*
        { icon: <HelpCircle className="h-6 w-6" />, title: "Help Center", component: <MarkdownDisplay fileName="help"/> },
    */
    {
        icon: <Truck className="h-6 w-6" />,
        title: "Ship+Receive",
        component: <div />,
        subItems: [
            { icon: <Truck className="h-6 w-6" />, title: "Deliveries", component: <TablePanelPlusPhotos docType="Document" columns={tableConfig.deliveries.columns} itemTitle="Deliveries" endpoints={tableConfig.deliveries.endpoints} showTimestamp={true} /> },
            { icon: <Box className="h-6 w-6" />, title: "Ingredient Receiving", component: <TablePanelPlusPhotos columns={tableConfig.ingredientReceiving.columns} docType="Document" itemTitle="Ingredient Receiving" endpoints={tableConfig.ingredientReceiving.endpoints} showTimestamp={true} /> },
            { icon: <Package2 className="h-6 w-6" />, title: "Packaging Receiving", component: <TablePanelPlus columns={tableConfig.packagingReceiving.columns} itemTitle="Packaging Receiving" endpoints={tableConfig.packagingReceiving.endpoints} showTimestamp={true} /> },
            { icon: <Apple className="h-6 w-6" />, title: "Ingredients Inventory", component: <InventoryManagement inventoryType="ingredient" /> },
            { icon: <Utensils className="h-6 w-6" />, title: "Product Inventory", component: <InventoryManagement inventoryType="product" /> },
            { icon: <Truck className="h-6 w-6" />, title: "Shipping", component: <Shipping /> },
        ]
    },
    {
        icon: <ListChecks className="h-6 w-6" />,
        title: "Lists",
        component: <div />,
        subItems: [
            { icon: <Tags className="h-6 w-6" />, title: "Brands", component: <TablePanelPlus columns={tableConfig.brands.columns} itemTitle="Brand" endpoints={tableConfig.brands.endpoints} /> },
            { icon: <Building className="h-6 w-6" />, title: "Clients", component: <TablePanel columns={tableConfig.clients.columns} itemTitle="Clients" endpoints={tableConfig.clients.endpoints} /> },
            { icon: <Store className="h-6 w-6" />, title: "Distributors", component: <TablePanel columns={tableConfig.distributors.columns} itemTitle="Distributor" endpoints={tableConfig.distributors.endpoints} /> },
            { icon: <Wrench className="h-6 w-6" />, title: "Equipment", component: <TablePanelPlusPhotos columns={tableConfig.equipment.columns} itemTitle="Equipment" endpoints={tableConfig.equipment.endpoints} /> },
            { icon: <Wheat className="h-6 w-6" />, title: "All Ingredients", component: <TablePanel columns={tableConfig.ingredients.columns} itemTitle="Ingredient" endpoints={tableConfig.ingredients.endpoints} /> },
            { icon: <Wheat className="h-6 w-6" />, title: "Ingredients By Client", component: <TablePanelPlusPhotos columns={tableConfig.myIngredients.columns} ro={true} itemTitle="Ingredient" endpoints={tableConfig.myIngredients.endpoints} /> },
            { icon: <Store className="h-6 w-6" />, title: "Ingredient Suppliers", component: <TablePanel columns={tableConfig.ingredientSuppliers.columns} itemTitle="Ingredient Supplier" endpoints={tableConfig.ingredientSuppliers.endpoints} /> },
            { icon: <ClipboardList className="h-6 w-6" />, title: "Inspection Items", component: <TablePanelFiltered columns={tableConfig.list_items.columns} itemTitle="Inspection Item" endpoints={tableConfig.list_items.endpoints} filterBy={{type: 'list', title: 'Inspection Type', col: 'list_id'}} /> },
            { icon: <ListOrdered className="h-6 w-6" />, title: "Inspection Types", component: <TablePanel byClient={true} columns={tableConfig.lists.columns} itemTitle="Inspection Type" endpoints={tableConfig.lists.endpoints} /> },
            { icon: <ShoppingBag className="h-6 w-6" />, title: "Products", component: <TablePanelPlusPhotos columns={tableConfig.products.columns} itemTitle="Product" endpoints={tableConfig.products.endpoints} /> },
            { icon: <Tags className="h-6 w-6" />, title: "Product Categories", component: <TablePanelPlus columns={tableConfig.productCategories.columns} itemTitle="Product Category" endpoints={tableConfig.productCategories.endpoints} /> },
            { icon: <Boxes className="h-6 w-6" />, title: "Pre Operational Cleaning Check", component: <TablePanel columns={tableConfig.packaging.columns} itemTitle="Packaging" endpoints={tableConfig.packaging.endpoints} /> },
            { icon: <Boxes className="h-6 w-6" />, title: "Packaging Types", component: <TablePanel columns={tableConfig.packaging.columns} itemTitle="Packaging" endpoints={tableConfig.packaging.endpoints} /> },
            { icon: <Store className="h-6 w-6" />, title: "Packaging Suppliers", component: <TablePanel columns={tableConfig.packagingSuppliers.columns} itemTitle="Packaging Supplier" endpoints={tableConfig.packagingSuppliers.endpoints} /> },
            { icon: <ClipboardList className="h-6 w-6" />, title: "Scheduled Processes", component: <ScheduledProcessTable /> },
        ]
    },
    {
        icon: <FileText className="h-6 w-6" />,
        title: "Documents",
        component: <div />,
        subItems: [
            { icon: <Bug className="h-6 w-6" />, title: "Pest Control", component: <PestControl /> },
            { icon: <FileStack className="h-6 w-6" />, title: "Company Docs", component: <TablePanelPlusPhotos columns={tableConfig.documents.columns} docType="Document" itemTitle="Document" endpoints={tableConfig.documents.endpoints} /> },
            { icon: <FileStack className="h-6 w-6" />, title: "Facility Docs", component: <TablePanelPlusPhotos columns={tableConfig.documents.columns} client="facility" ro={true} docType="Document" itemTitle="Document" endpoints={tableConfig.documents.endpoints} /> },
            { icon: <FileStack className="h-6 w-6" />, title: "Copacker Docs", component: <TablePanelPlusPhotos columns={tableConfig.documents.columns} client="copacker" ro={true} docType="Document" itemTitle="Document" endpoints={tableConfig.documents.endpoints} /> },
        ]
    },
    {
        icon: <Factory className="h-6 w-6" />,
        title: "Facility",
        component: <div />,
        subItems: [
            { icon: <Bug className="h-6 w-6" />, title: "Pest Control", component: <PestControl /> },
            { icon: <FileStack className="h-6 w-6" />, title: "Facility Docs", component: <TablePanelPlusPhotos columns={tableConfig.documents.columns} client="facility" ro={true} docType="Document" itemTitle="Document" endpoints={tableConfig.documents.endpoints} /> },
            { icon: <Wrench className="h-6 w-6" />, title: "Facility Equipment", component: <TablePanelPlusPhotos columns={tableConfig.equipment.columns} client="facility" ro={true} itemTitle="Equipment" endpoints={tableConfig.equipment.endpoints} /> },
        ]
    },
    {
        icon: <Settings className="h-6 w-6" />,
        title: "Other",
        component: <div />,
        subItems: [
            { icon: <Info className="h-6 w-6" />, title: "About", component: <About /> },
            { icon: <UserCog className="h-6 w-6" />, title: "Account", component: <AccountInfo /> },
            { icon: <CalendarDays className="h-6 w-6" />, title: "Batches By Date", component: <BatchesByDate /> },
            { icon: <Library className="h-6 w-6" />, title: "Client Sales Sheet", component: <CatalogPanel /> },
            { icon: <AlertTriangle className="h-6 w-6" />, title: "Incidents", component: <TablePanel columns={tableConfig.incidents.columns} itemTitle="Incident" endpoints={tableConfig.incidents.endpoints} showTimestamp={true} /> },
            { icon: <Network className="h-6 w-6" />, title: "Lot Tracking Menu", component: <FlowChartMenu onNodeClick={handleNodeClick} chartDefinition={lotCodeChart} /> },
            { icon: <Info className="h-6 w-6" />, title: "Organic Audit", component: <DynamicComponentLoader title="Organic Audit" componentsData={auditItems} /> },
            { icon: <Camera className="h-6 w-6" />, title: "Photos", component: <PhotoMenu onAction={handleMenuAction}/> },
            { icon: <Network className="h-6 w-6" />, title: "Process Flow Menu", component: <FlowChartMenu onNodeClick={handleNodeClick} chartDefinition={productionChart} /> },
            { icon: <Waypoints className="h-6 w-6" />, title: "Projects", component: <Blank /> },
            { icon: <AlertOctagon className="h-6 w-6" />, title: "Recall", component: <Recall /> },
            { icon: <BarChart3 className="h-6 w-6" />, title: "Review Inspections", component: <PanelTwo /> },
            { icon: <Users className="h-6 w-6" />, title: "Visitors", component: <TablePanel columns={tableConfig.visitors.columns} itemTitle="Visitor" endpoints={tableConfig.visitors.endpoints} showTimestamp={true} /> },
        ]
    },
    {
        icon: <UserCog className="h-6 w-6" />,
        title: "Manage Clients",
        component: <div />,
        subItems: [
            { icon: <Building className="h-6 w-6" />, title: "Clients", component: <TablePanel columns={tableConfig.clients.columns} itemTitle="Clients" endpoints={tableConfig.clients.endpoints} /> },
            { icon: <Receipt className="h-6 w-6" />, title: "Invoices", component: <InvoiceTable /> },
        ]
    },
    { icon: <HelpCircle className="h-6 w-6" />, title: "Help Center", component: <MarkdownDisplay fileName="help"/> },
    { icon: <ChevronRight className="h-6 w-6" />, title: "Logout", component: <div /> },
];

export default getMenuItems;