// MobileItemsView.tsx - Mobile-friendly card layout for equipment items

import React from 'react';
import {
    Box, Paper, Typography, Grid, FormControl, FormLabel,
    RadioGroup, FormControlLabel, Radio, TextField
} from '@mui/material';
import { EquipmentItem, EquipmentItemState, FormDataState } from './types';

interface MobileItemsViewProps {
    equipmentItems: EquipmentItem[];
    formData: FormDataState;
    onRadioChange: (itemId: string, field: keyof EquipmentItemState, value: string) => void;
    onTextChange: (itemId: string, value: string) => void;
}

const MobileItemsView: React.FC<MobileItemsViewProps> = ({
                                                             equipmentItems,
                                                             formData,
                                                             onRadioChange,
                                                             onTextChange
                                                         }) => {
    return (
        <Box sx={{ my: 2 }}>
            {equipmentItems.map((item) => (
                <Paper key={item.id} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom align="center" sx={{ bgcolor: 'action.hover', py: 1, borderRadius: 1 }}>
                        {item.name}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Clean</FormLabel>
                                <RadioGroup
                                    row
                                    name={`${item.id}_clean_mobile`}
                                    value={formData.items[item.id].clean}
                                    onChange={(e) => onRadioChange(item.id, 'clean', e.target.value)}
                                >
                                    <FormControlLabel value="Yes" control={<Radio required />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio required />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">If No Corrective Action</FormLabel>
                                <RadioGroup
                                    row
                                    name={`${item.id}_correctiveAction_mobile`}
                                    value={formData.items[item.id].correctiveAction}
                                    onChange={(e) => onRadioChange(item.id, 'correctiveAction', e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Corrective Action Completed</FormLabel>
                                <RadioGroup
                                    row
                                    name={`${item.id}_completed_mobile`}
                                    value={formData.items[item.id].completed}
                                    onChange={(e) => onRadioChange(item.id, 'completed', e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Equipment Operational</FormLabel>
                                <RadioGroup
                                    row
                                    name={`${item.id}_operational_mobile`}
                                    value={formData.items[item.id].operational}
                                    onChange={(e) => onRadioChange(item.id, 'operational', e.target.value)}
                                >
                                    <FormControlLabel value="Yes" control={<Radio required />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio required />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Comments"
                                variant="outlined"
                                size="small"
                                value={formData.items[item.id].comments}
                                onChange={(e) => onTextChange(item.id, e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Paper>
            ))}
        </Box>
    );
};

export default MobileItemsView;