// src/components/Catalog/Catalog.tsx

import React, { FC, useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import ProductPanel from './ProductPanel';
import OrderingPanel from './OrderingPanel';
import './catalog.scss';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import { host, hostOld } from "../tableConfig";

interface Store {
    id: number;
    name: string;
    dlevel: number | null;
}

interface CatalogProps {
    ordering?: boolean;
}

const Catalog: FC<CatalogProps> = ({ ordering = false }) => {
    const [items, setItems] = useState<any[]>([]);
    const [stores, setStores] = useState<Store[]>([]);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        const fetchData = async () => {
            await fetchCatalogInfo();
        };
        fetchData();
    }, []);

    const fetchCatalogInfo = async () => {
        const endpoint = `${hostOld}/public_api/getCatalogInfoSR`;
        try {
            const response: any = await axiosWrapper({ url: endpoint });
            setItems(response?.data?.product_groups || []);
            setStores(response?.data?.stores || []);
        } catch (error) {
            console.error(`Error fetching catalog info:`, error);
        }
    };

    return (
        <div className="catalog-container">
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    {ordering && stores.length > 0 && (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className="ordering-grid-item"
                        >
                            <OrderingPanel stores={stores} />
                        </Grid>
                    )}

                    {items.map((product) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={product.id}
                            className="product-grid-item"
                        >
                            <ProductPanel product={product} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default Catalog;