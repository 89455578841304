// DesktopItemsView.tsx - Table layout for equipment items on desktop

import React from 'react';
import {
    TableContainer, Paper, Table, TableHead, TableBody,
    TableRow, TableCell, RadioGroup, FormControlLabel,
    Radio, TextField
} from '@mui/material';
import { EquipmentItem, EquipmentItemState, FormDataState } from './types';

interface DesktopItemsViewProps {
    equipmentItems: EquipmentItem[];
    formData: FormDataState;
    onRadioChange: (itemId: string, field: keyof EquipmentItemState, value: string) => void;
    onTextChange: (itemId: string, value: string) => void;
}

const DesktopItemsView: React.FC<DesktopItemsViewProps> = ({
                                                               equipmentItems,
                                                               formData,
                                                               onRadioChange,
                                                               onTextChange
                                                           }) => {
    return (
        <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Equipment</TableCell>
                        <TableCell>Clean</TableCell>
                        <TableCell>If No Corrective Action</TableCell>
                        <TableCell>Corrective Action Completed</TableCell>
                        <TableCell>Equipment Operational</TableCell>
                        <TableCell>Comments</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {equipmentItems.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                                <RadioGroup
                                    row
                                    name={`${item.id}_clean`}
                                    value={formData.items[item.id].clean}
                                    onChange={(e) => onRadioChange(item.id, 'clean', e.target.value)}
                                >
                                    <FormControlLabel value="Yes" control={<Radio required />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio required />} label="No" />
                                </RadioGroup>
                            </TableCell>
                            <TableCell>
                                <RadioGroup
                                    row
                                    name={`${item.id}_correctiveAction`}
                                    value={formData.items[item.id].correctiveAction}
                                    onChange={(e) => onRadioChange(item.id, 'correctiveAction', e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </TableCell>
                            <TableCell>
                                <RadioGroup
                                    row
                                    name={`${item.id}_completed`}
                                    value={formData.items[item.id].completed}
                                    onChange={(e) => onRadioChange(item.id, 'completed', e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio disabled={formData.items[item.id].clean !== 'No'} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </TableCell>
                            <TableCell>
                                <RadioGroup
                                    row
                                    name={`${item.id}_operational`}
                                    value={formData.items[item.id].operational}
                                    onChange={(e) => onRadioChange(item.id, 'operational', e.target.value)}
                                >
                                    <FormControlLabel value="Yes" control={<Radio required />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio required />} label="No" />
                                </RadioGroup>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={formData.items[item.id].comments}
                                    onChange={(e) => onTextChange(item.id, e.target.value)}
                                    fullWidth
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DesktopItemsView;