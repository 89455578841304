// src/components/ProductPanel/ProductPanel.tsx

import React, { FC, useState, useEffect } from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Divider,
    Box,
    Paper,
    List,
    ListItem,
    Chip
} from '@mui/material';
import { Product } from './catalogData';
import './ProductPanel.scss';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import { host, hostOld } from "../tableConfig";
import ReactMarkdown from 'react-markdown';

interface ProductPanelProps {
    product: Product;
}

interface ProductVariety {
    id: string;
    variety: string;
    descr: string;
    packaging: string;
    metric_name: string;
    weight_or_volume: number;
    price: number;
    case_size: number;
    d1: string;
    sku: string;
    brand_key: string;
    brand: string;
}

interface ProductGroupData {
    productGroup: {
        id: number;
        group_name: string;
        group_descr: string;
        images: string; // Images is a comma-separated string
        long_descr: string;
        brand: string;
        brand_key: string;
    };
    products: ProductVariety[];
}

const ProductPanel: FC<ProductPanelProps> = ({ product }) => {
    const [open, setOpen] = useState(false);
    const [productGroupData, setProductGroupData] = useState<ProductGroupData | null>(null);
    const [loading, setLoading] = useState(false);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    const handleOpen = async () => {
        setOpen(true);
        await fetchProductGroupData();
    };

    const fetchProductGroupData = async () => {
        setLoading(true);
        try {
            const endpoint = `${hostOld}/public_api/product-group/${product.id}`;
            const response = await axiosWrapper({ url: endpoint });

            if (response?.data?.data) {
                setProductGroupData(response.data.data);
            }
        } catch (error) {
            console.error(`Error fetching product group data:`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Group products by package size and oz
    const groupedProducts = React.useMemo(() => {
        if (!productGroupData?.products) return [];

        const grouped = productGroupData.products.reduce((acc, item) => {
            const key = `${item.metric_name}-${item.weight_or_volume}-${item.packaging}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {} as Record<string, ProductVariety[]>);

        return Object.values(grouped);
    }, [productGroupData]);

    // Render product images
    const renderImages = () => {
        if (!productGroupData?.productGroup?.images) {
            return (
                <CardMedia
                    component="img"
                    image={`https://saltroad.example:3001/media/product_groups/${product.id}.jpg`}
                    alt={product.name}
                    sx={{ maxHeight: 700, objectFit: 'contain', mb: 2 }}
                />
            );
        }

        // Split the comma-separated image string
        const imageArray = productGroupData.productGroup.images.split(',').filter(img => img.trim() !== '');

        return imageArray.map((image, index) => (
            <CardMedia
                key={index}
                component="img"
                image={`${hostOld}/media/product_photos/${productGroupData.productGroup.brand_key.toLowerCase()}/${image.trim()}`}
                alt={`${product.name} ${index + 1}`}
                sx={{ maxHeight: 700, objectFit: 'contain', mb: 2 }}
            />
        ));
    };

    return (
        <div className="product-panel">
            <Card className="product-card" onClick={handleOpen}>
                <CardMedia
                    component="img"
                    image={`https://saltroad.example:3001/media/product_groups/${product.id}.jpg`}
                    alt={product.name}
                    className="product-image"
                />
                <div className="overlay-text">
                    <Typography variant="h6" className="brand-name">
                        {product.brand}
                    </Typography>
                    <Typography variant="subtitle1" className="product-name">
                        {product.name}
                    </Typography>
                </div>
                <CardContent className="product-description">
                    <Typography variant="body2">
                        {product.descr}
                    </Typography>
                </CardContent>
            </Card>

            {/* Product Detail Dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        maxHeight: '90vh',
                        margin: '0px', // Set margin to 0
                        width: '98%', // Make the dialog take up almost the full width
                        maxWidth: '800px'
                    }
                }}
            >
                <DialogTitle>
                    <Typography variant="h5">
                        <strong>{product.brand}</strong> - {product.name}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {loading ? (
                        <Typography>Loading product details...</Typography>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {productGroupData?.productGroup?.group_descr || product.descr}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                            </Grid>

                            {groupedProducts.map((group, index) => (
                                <Grid item xs={12} key={index}>
                                    <Paper elevation={0} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                                        <Typography variant="h6">
                                            {group.length === 1 ? group[0].variety + ':' : ''} {group[0].weight_or_volume} {group[0].metric_name} {group[0].packaging} : ${Number(group[0].price).toFixed(2)}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Case size: {group[0].case_size}
                                        </Typography>

                                        {group.length > 1 && (
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="subtitle2">Varieties:</Typography>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 0.5 }}>
                                                    {group.map((item, vIndex) => (
                                                        <Chip
                                                            key={vIndex}
                                                            label={item.variety}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Paper>
                                </Grid>
                            ))}

                            {productGroupData?.productGroup?.long_descr && (
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                    <ReactMarkdown
                                        className="markdown-content"
                                        components={{
                                            // Fix the p component definition to satisfy TypeScript
                                            p: ({node, ...props}) => (
                                                <Typography variant="body2" gutterBottom>
                                                    {props.children}
                                                </Typography>
                                            )
                                        }}
                                    >
                                        {productGroupData.productGroup.long_descr}
                                    </ReactMarkdown>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Box sx={{ mt: 2 }}>
                                    {renderImages()}
                                </Box>
                            </Grid>

                            {/* The ingredients are already in the long_descr field, so we don't need this section */}
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button color="primary" variant="contained">
                        Add to Cart
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProductPanel;