import { EquipmentCheck } from './types';

/**
 * Converts server data format to the client-side format used in the application
 * @param serverData - The data returned from the server API
 * @returns An array of EquipmentCheck objects formatted for the client
 */
export const convertServerData = (serverData: any[]): EquipmentCheck[] => {
    return serverData.map(item => {
        // Convert Buffer data to base64 string for signature
        let signatureBase64 = '';
        if (item.signature && item.signature.type === 'Buffer' && Array.isArray(item.signature.data)) {
            try {
                // Convert Buffer data array to binary string (browser-compatible)
                const binary = item.signature.data.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
                // Convert binary to base64
                const base64 = btoa(binary);
                signatureBase64 = `data:image/png;base64,${base64}`;
            } catch (error) {
                console.error('Error converting signature:', error);
                signatureBase64 = '';
            }
        }

        // Format dates if needed
        const inspectionDate = item.inspection_date ?
            new Date(item.inspection_date).toISOString().split('T')[0] : '';

        const reviewDate = item.review_date ?
            new Date(item.review_date).toISOString().split('T')[0] : '';

        // Return the converted object in the format expected by the client
        return {
            id: item.id,
            inspectionDate,
            reviewedBy: item.reviewed_by,
            reviewDate,
            items: item.form_data.items,
            signature: signatureBase64
        };
    });
};

/**
 * Example usage:
 *
 * // Import the server data
 * import serverData from './api/data.json';
 *
 * // Convert it to client format
 * const clientData = convertServerData(serverData);
 *
 * // Now you can use it in your component
 * const [checksData, setChecksData] = useState(clientData);
 */


/**
 * Convert client model to server model
 * @param check - The client equipment check model
 * @returns The server model
 */
export const toServerModel = (check: Omit<EquipmentCheck, 'id'>): any => {
    // Convert the signature from base64 to Binary if needed
    let signature:any = null;
    if (check.signature && check.signature.startsWith('data:image/')) {
        // Extract the base64 part
        const base64 = check.signature.split(',')[1];
        // Convert to an array of bytes
        const binary = atob(base64);
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }
        signature = {
            type: 'Buffer',
            data: Array.from(bytes)
        };
    }

    return {
        inspection_date: check.inspectionDate,
        review_date: check.reviewDate,
        reviewed_by: check.reviewedBy,
        form_data: {
            items: check.items
        },
        signature
    };
}