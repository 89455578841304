// src/components/OrderingPanel/OrderingPanel.tsx

import React, { FC, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    SelectChangeEvent,
    Chip,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './OrderingPanel.scss';

interface Store {
    id: number;
    name: string;
    dlevel: number | null;
}

interface OrderingPanelProps {
    stores: Store[];
}

const OrderingPanel: FC<OrderingPanelProps> = ({ stores }) => {
    const [selectedStore, setSelectedStore] = useState<string>('');
    const [selectedStoreName, setSelectedStoreName] = useState<string>('');

    const handleStoreChange = (event: SelectChangeEvent) => {
        const storeId = event.target.value;
        setSelectedStore(storeId);

        // Find the store name that matches the selected ID
        const store = stores.find(s => s.id.toString() === storeId);
        if (store) {
            setSelectedStoreName(store.name);
        }
    };

    const handleClearStore = () => {
        setSelectedStore('');
        setSelectedStoreName('');
    };

    return (
        <div className="ordering-panel">
            <Card className="ordering-card">
                <div className="ordering-header">
                    <Typography variant="h5" className="ordering-title">
                        Start Your Order
                    </Typography>
                </div>
                <CardContent>
                    <Box sx={{ mt: 2 }}>
                        {!selectedStore ? (
                            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                                <InputLabel id="store-select-label">Store Location</InputLabel>
                                <Select
                                    labelId="store-select-label"
                                    id="store-select"
                                    value={selectedStore}
                                    onChange={handleStoreChange}
                                    label="Store Location"
                                >
                                    <MenuItem value="" disabled>
                                        <em>Select a store</em>
                                    </MenuItem>
                                    {stores.map((store) => (
                                        <MenuItem key={store.id} value={store.id.toString()}>
                                            {store.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', p: 1, border: '1px solid #e0e0e0', borderRadius: '4px', mb: 2 }}>
                                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                    <strong>Selected Store:</strong> {selectedStoreName}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={handleClearStore}
                                    aria-label="clear store selection"
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default OrderingPanel;