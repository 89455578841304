// EquipmentTable.tsx - Main component for displaying equipment checks

import React, {useEffect, useState} from 'react';
import {
    Box, Button, TableContainer, Paper, Table, TableHead,
    TableBody, TableRow, TableCell, TableSortLabel
} from '@mui/material';
import { EquipmentCheck, MOCK_CHECKS_DATA } from './types';
import EquipmentCheckFormDialog from './EquipmentCheckFormDialog';
import EquipmentCheckFormRO from './EquipmentCheckFormRO';
import useUserStore from "../store/userStore";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {createAxiosWrapper} from "../Util";
import {convertServerData, toServerModel} from "./util";

interface TablePanelProps {
    endpoints: any;
}

// Define sort direction type
type Order = 'asc' | 'desc';

// Define sort property type
type OrderBy = 'inspectionDate' | 'reviewedBy';

const EquipmentTable: React.FC<TablePanelProps> = ({endpoints}) => {
    const [openForm, setOpenForm] = useState(false);
    const [openReadOnly, setOpenReadOnly] = useState(false);
    const [editingCheck, setEditingCheck] = useState<EquipmentCheck | null>(null);
    const [checksData, setChecksData] = useState<EquipmentCheck[]>(MOCK_CHECKS_DATA);
    const { user } = useUserStore();
    const clientId = user?.clientId;
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);
    const [mode, setMode] = useState<'add' | 'view' | 'edit'>('add');

    // Sorting states
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<OrderBy>('inspectionDate');

    const fetchChecksData = async () => {
        try {
            const response = await axiosWrapper({url: `${endpoints.get}?clientId=${clientId}`})
            let data = response.data.data
            data = convertServerData(data)
            console.log('X', data)

            // Sort the data when it arrives
            const sortedData = sortData(data || [], orderBy, order);
            setChecksData(sortedData);
        } catch (error) {
            console.error(`Error fetching Equipment PreCheck Data`, error)
        }
    };

    useEffect(() => {
        fetchChecksData();
    }, []);

    // Function to sort the data
    const sortData = (data: EquipmentCheck[], property: OrderBy, sortOrder: Order): EquipmentCheck[] => {
        const stabilizedThis = data.map((el, index) => [el, index] as [EquipmentCheck, number]);

        stabilizedThis.sort((a, b) => {
            const aValue = a[0][property];
            const bValue = b[0][property];

            // For dates, convert to timestamp for comparison
            if (property === 'inspectionDate') {
                const aDate = aValue ? new Date(aValue).getTime() : 0;
                const bDate = bValue ? new Date(bValue).getTime() : 0;

                return sortOrder === 'desc' ? bDate - aDate : aDate - bDate;
            }

            // For strings
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortOrder === 'desc'
                    ? bValue.localeCompare(aValue)
                    : aValue.localeCompare(bValue);
            }

            return 0;
        });

        return stabilizedThis.map(el => el[0]);
    };

    // Handle sort request
    const handleRequestSort = (property: OrderBy) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        // Apply the sort to current data
        const sortedData = sortData([...checksData], property, newOrder);
        setChecksData(sortedData);
    };

    // Open form to add new check
    const handleAddNew = () => {
        setMode('add');
        setEditingCheck(null);
        setOpenForm(true);
        setOpenReadOnly(false);
    };

    // Open read-only view when clicking on a row
    const handleViewCheck = (check: EquipmentCheck) => {
        setMode('view');
        setEditingCheck(check);
        setOpenReadOnly(true);
        setOpenForm(false);
    };

    // Switch from read-only to edit mode
    const handleSwitchToEdit = () => {
        setMode('edit');
        setOpenReadOnly(false);
        setOpenForm(true);
    };

    // Save check (add new or update existing)
    const handleSave = async (newItem: Omit<EquipmentCheck, 'id'>) => {
        console.log('Saving check data:', newItem);

        try {
            const endpoint = endpoints.post + `?clientId=${clientId}`;
            const data = toServerModel(newItem)
            await axiosWrapper({url: endpoint, data, method: 'post'});
            await fetchChecksData();
            setOpenForm(false);
        } catch (error) {
            console.error(`Error saving Pre Check:`, error);
            throw error;
        }
    };

    return (
        <Box sx={{
            maxWidth: '600px',
            margin: '0 auto 80px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '12px',
            paddingRight: '12px'
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '20px',
            }}>
                <Button
                    variant="contained"
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                    onClick={handleAddNew}
                >
                    Add Equipment Pre-check
                </Button>

                {/* Equipment checks table with sorting */}
                <TableContainer component={Paper} sx={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'inspectionDate'}
                                        direction={orderBy === 'inspectionDate' ? order : 'asc'}
                                        onClick={() => handleRequestSort('inspectionDate')}
                                    >
                                        Inspection Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'reviewedBy'}
                                        direction={orderBy === 'reviewedBy' ? order : 'asc'}
                                        onClick={() => handleRequestSort('reviewedBy')}
                                    >
                                        Reviewed By
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {checksData.map((check) => (
                                <TableRow
                                    key={check.id}
                                    hover
                                    onClick={() => handleViewCheck(check)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{check.inspectionDate}</TableCell>
                                    <TableCell>{check.reviewedBy}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Form dialogs */}
            <EquipmentCheckFormDialog
                open={openForm}
                onClose={() => setOpenForm(false)}
                onSave={handleSave}
                editingCheck={editingCheck}
                mode={mode}
            />

            {editingCheck && (
                <EquipmentCheckFormRO
                    open={openReadOnly}
                    onClose={() => setOpenReadOnly(false)}
                    onEdit={handleSwitchToEdit}
                    check={editingCheck}
                />
            )}
        </Box>
    );
};

export default EquipmentTable;