// EquipmentCheckFormRO.tsx - Read-only version of the equipment check form for printing

import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton, Button, Grid, Box, Typography, Paper,
    Table, TableHead, TableBody, TableRow, TableCell,
    useMediaQuery, Theme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EquipmentCheck, EQUIPMENT_ITEMS } from './types';

interface EquipmentCheckFormROProps {
    open: boolean;
    onClose: () => void;
    onEdit: () => void;
    check: EquipmentCheck;
}

const EquipmentCheckFormRO: React.FC<EquipmentCheckFormROProps> = ({
                                                                       open,
                                                                       onClose,
                                                                       onEdit,
                                                                       check
                                                                   }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    // Format a field value as YES or NO
    const formatValue = (value: string) => {
        return value === 'Yes' ? 'YES' : value === 'No' ? 'NO' : '';
    };

    // Style for table cells to match original
    const cellStyle = {
        border: '1px solid black',
        padding: '12px',
        height: '45px', // Increased height
        verticalAlign: 'middle',
        fontSize: '16px', // Increased font size
    };

    // Header cell style
    const headerCellStyle = {
        ...cellStyle,
        backgroundColor: '#f2f2f2',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '16px', // Increased font size
        height: '60px', // Increased height for headers specifically
    };

    // Determine empty rows to add (further reduced)
    const emptyRowsCount = 2; // Reduced to fit on one page
    const emptyRows = Array.from({ length: emptyRowsCount }).map((_, index) => index);

    // Mobile view - Card layout (reverted to previous version)
    const MobileView = () => (
        <Box sx={{ mb: 3 }}>
            {EQUIPMENT_ITEMS.map((item) => (
                <Paper key={item.id} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" align="center" sx={{ mb: 2, bgcolor: 'action.hover', py: 1, borderRadius: 1 }}>
                        {item.name}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Clean:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{formatValue(check.items[item.id].clean)}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle2">If No Corrective Action:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                {check.items[item.id].clean === 'No' ? formatValue(check.items[item.id].correctiveAction) : '-'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Corrective Action Completed:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                {check.items[item.id].clean === 'No' ? formatValue(check.items[item.id].completed) : '-'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Equipment Operational:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{formatValue(check.items[item.id].operational)}</Typography>
                        </Grid>

                        {check.items[item.id].comments && (
                            <>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2">Comments:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{check.items[item.id].comments}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>
            ))}
        </Box>
    );

    // Desktop View - Matches original PDF format
    const DesktopView = () => (
        <>
            {/* Custom header to match original format */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 1,
                mt: 2,
                '@media print': { mb: 2 }
            }}>
                <Typography
                    variant="h5"
                    component="h1"
                    className="title-typography"
                    sx={{
                        fontWeight: 'normal',
                        fontStyle: 'italic',
                        fontSize: '2rem', // Significantly increased font size
                        '@media print': { fontSize: '3rem' }
                    }}
                >
                    Pre Operational Cleaning Check LOG
                </Typography>
            </Box>

            {/* Date field in top right corner */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mb: 2,
                '@media print': { mb: 2 }
            }}>
                <Typography sx={{ fontSize: '1.2rem' }}>
                    DATE: {check.inspectionDate}
                </Typography>
            </Box>

            {/* Main table styled to match original */}
            <Paper elevation={0} sx={{
                overflow: 'auto',
                mb: 2,
                '@media print': { mb: 2 },
                border: 'none',
                boxShadow: 'none'
            }}>
                <Table sx={{
                    borderCollapse: 'collapse',
                    width: '100%',
                    border: '2px solid black',
                    '@media print': { borderCollapse: 'collapse' }
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...headerCellStyle, width: '10%' }}>Equipment</TableCell>
                            <TableCell sx={{ ...headerCellStyle, width: '10%' }}>Clean Yes/No</TableCell>
                            <TableCell sx={{ ...headerCellStyle, width: '10%' }}>If No Corrective Action</TableCell>
                            <TableCell sx={{ ...headerCellStyle, width: '15%' }}>
                                Corrective Action Completed Yes/No
                            </TableCell>
                            <TableCell sx={{ ...headerCellStyle, width: '15%' }}>Equipment Operational</TableCell>
                            <TableCell sx={{ ...headerCellStyle, width: '40%' }}>COMMENTS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Equipment items with data */}
                        {EQUIPMENT_ITEMS.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell sx={cellStyle}>{item.name}</TableCell>
                                <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                    {formatValue(check.items[item.id].clean)}
                                </TableCell>
                                <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                    {check.items[item.id].clean === 'No' ? formatValue(check.items[item.id].correctiveAction) : ''}
                                </TableCell>
                                <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                    {check.items[item.id].clean === 'No' ? formatValue(check.items[item.id].completed) : ''}
                                </TableCell>
                                <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
                                    {formatValue(check.items[item.id].operational)}
                                </TableCell>
                                <TableCell sx={cellStyle}>
                                    {check.items[item.id].comments}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Empty rows to match original format */}
                        {emptyRows.map((index) => (
                            <TableRow key={`empty-${index}`}>
                                <TableCell sx={cellStyle}></TableCell>
                                <TableCell sx={cellStyle}></TableCell>
                                <TableCell sx={cellStyle}></TableCell>
                                <TableCell sx={cellStyle}></TableCell>
                                <TableCell sx={cellStyle}></TableCell>
                                <TableCell sx={cellStyle}></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            {/* Reviewed by section at bottom */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                mb: 2,
                '@media print': { mt: 2, mb: 2 }
            }}>
                <Typography sx={{ fontSize: '1.2rem' }}>
                    REVIEWED BY: {check.reviewedBy}
                </Typography>
                <Typography sx={{ fontSize: '1.2rem' }}>
                    DATE: {check.reviewDate}
                </Typography>
            </Box>
        </>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg" // Changed from "md" to "lg" for wider dialog
            fullScreen={isMobile}
            PaperProps={{
                sx: {
                    '@media print': {
                        width: '100%',
                        maxWidth: 'none',
                        height: 'auto',
                        margin: 0,
                        boxShadow: 'none'
                    }
                }
            }}
        >
            <DialogTitle sx={{
                display: isMobile ? 'block' : 'none', // Show title only on mobile
                '@media print': { display: 'none' }
            }}>
                Pre-Operational Cleaning Check #{check.id}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        '@media print': { display: 'none' }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
                '@media print': {
                    padding: { xs: '5mm', sm: '5mm', md: '5mm' },
                    width: '100%',
                    maxWidth: 'none',
                    overflow: 'visible'
                }
            }}>
                {isMobile ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <strong>Inspection Date:</strong> {check.inspectionDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">
                                <strong>Review Date:</strong> {check.reviewDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <strong>Reviewed By:</strong> {check.reviewedBy}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <MobileView />
                        </Grid>
                    </Grid>
                ) : (
                    <DesktopView />
                )}

                {/* Signature only shown in UI, not in print version */}
                <Box sx={{
                    mt: 2,
                    '@media print': { display: 'none' }
                }}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Signature:</strong>
                    </Typography>
                    <img
                        src={check.signature}
                        alt="Signature"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            maxHeight: '150px',
                            border: '1px solid #ccc'
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ '@media print': { display: 'none' } }}>
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                {/*<Button
                    onClick={onEdit}
                    variant="contained"
                    color="primary"
                >
                    Edit
                </Button>*/}
                <Button
                    onClick={() => {
                        // Add styling for landscape mode printing with almost edge-to-edge layout
                        const style = document.createElement('style');
                        style.innerHTML = `
              @page {
                size: landscape;
                margin: 0; /* No margin for maximum space */
              }
              @media print {
                body, html {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  overflow: hidden !important;
                }
                /* Make content fill almost the entire page */
                .MuiDialog-paper {
                  width: 100% !important;
                  max-width: none !important;
                  margin: 0 !important;
                  max-height: 100% !important; /* Ensure it fits on one page */
                  page-break-after: avoid !important; /* Prevent page break */
                  overflow: hidden !important;
                }
                .MuiDialogContent-root {
                  width: 100% !important;
                  padding: 2mm !important;
                  height: 100% !important;
                  max-height: 100% !important;
                  overflow: hidden !important;
                }
                table {
                  width: 100% !important;
                  max-width: none !important;
                  height: 70vh !important; /* Force table to take most of page height */
                  page-break-inside: avoid !important; /* Prevent table from breaking across pages */
                }
                /* Enforce column widths with !important */
                th:nth-child(1), td:nth-child(1) { width: 10% !important; }
                th:nth-child(2), td:nth-child(2) { width: 5% !important; }
                th:nth-child(3), td:nth-child(3) { width: 8% !important; }
                th:nth-child(4), td:nth-child(4) { width: 10% !important; }
                th:nth-child(5), td:nth-child(5) { width: 7% !important; }
                th:nth-child(6), td:nth-child(6) { width: 60% !important; }
                
                /* Font size adjustments - MUCH larger */
                th, td {
                  font-size: 24pt !important;
                  padding: 10px !important;
                }
                
                /* Make header rows taller */
                thead tr {
                  height: 200px !important; /* Double the header height */
                }
                th {
                   line-height: 1.5 !important;
                }
                
                /* Increase row height to fill page */
                tbody tr {
                  height: 80px !important;
                }
                
                /* Make the entire form larger */
                .MuiTypography-root {
                  font-size: 24pt !important;
                }
                .title-typography {
                  font-size: 44pt !important;
                  margin-bottom: 10pt !important;
                  font-style: italic !important;
                  font-weight: normal !important;
                }
                
                /* Eliminate all possible sources of page breaks */
                * {
                  page-break-inside: avoid !important;
                  page-break-after: avoid !important;
                  page-break-before: avoid !important;
                }
              }
            `;
                        document.head.appendChild(style);
                        window.print();
                        // Clean up after print dialog closes
                        setTimeout(() => {
                            document.head.removeChild(style);
                        }, 1000);
                    }}
                    variant="outlined"
                >
                    Print
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EquipmentCheckFormRO;