// EquipmentCheckFormDialog.tsx - Dialog form for creating/editing equipment checks

import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton, Button, Grid, TextField, useMediaQuery, Theme, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    EquipmentCheck, FormDataState, getInitialFormData,
    EQUIPMENT_ITEMS, EquipmentItemState
} from './types';
import DesktopItemsView from './DesktopItemsView';
import MobileItemsView from './MobileItemsView';
import SignatureCanvas from './SignatureCanvas';
import useUserStore from "../store/userStore";

interface EquipmentCheckFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
    editingCheck: EquipmentCheck | null;
    mode: 'add' | 'view' | 'edit';
}

// Helper function to get today's date in YYYY-MM-DD format
const getTodayDate = (): string => {
    const today = new Date();
    return today.toISOString().split('T')[0];
};

// Modified getInitialFormData to include today's date
const getInitialFormDataWithToday = (items: typeof EQUIPMENT_ITEMS) => {
    const todayDate = getTodayDate();

    // Use the original function to get the item structure but override the dates
    const originalData = getInitialFormData(EQUIPMENT_ITEMS);

    return {
        checkDate: todayDate,
        reviewDate: todayDate,
        reviewedBy: '',
        items: originalData.items
    };
};

const EquipmentCheckFormDialog: React.FC<EquipmentCheckFormDialogProps> = ({
                                                                               open,
                                                                               onClose,
                                                                               onSave,
                                                                               editingCheck,
                                                                               mode
                                                                           }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [signature, setSignature] = useState<string | null>(null);
    const [formData, setFormData] = useState<FormDataState>(getInitialFormDataWithToday(EQUIPMENT_ITEMS));
    // Get user and clients from the store
    const { user } = useUserStore();

    // Load data when editing
    useEffect(() => {
        // Reset to initial state when opening the form, especially in 'add' mode
        if (!open) return;

        if (mode === 'add') {
            // When adding a new record, always start with clean form and no signature
            // Use our new function that includes today's date
            setFormData(getInitialFormDataWithToday(EQUIPMENT_ITEMS));
            setSignature(null);
        } else if (editingCheck && (mode === 'edit' || mode === 'view')) {
            // When editing or viewing, load the existing data
            setFormData({
                checkDate: editingCheck.inspectionDate,
                reviewDate: editingCheck.reviewDate,
                reviewedBy: editingCheck.reviewedBy,
                items: editingCheck.items
            });
            setSignature(editingCheck.signature);
        }
    }, [editingCheck, mode, open]);

    // Handle signature changes
    const handleSignatureChange = (newSignature: string | null) => {
        setSignature(newSignature);
    };

    // Handle radio button changes
    const handleRadioChange = (itemId: string, field: keyof EquipmentItemState, value: string) => {
        setFormData({
            ...formData,
            items: {
                ...formData.items,
                [itemId]: {
                    ...formData.items[itemId],
                    [field]: value
                }
            }
        });
    };

    // Handle text field changes
    const handleTextChange = (itemId: string, value: string) => {
        setFormData({
            ...formData,
            items: {
                ...formData.items,
                [itemId]: {
                    ...formData.items[itemId],
                    comments: value
                }
            }
        });
    };

    // Handle date and reviewer changes
    const handleFormChange = (field: keyof FormDataState, value: string) => {
        setFormData({
            ...formData,
            [field]: value
        });
    };

    // Form submission
    const handleSubmit = () => {
        if (!signature) {
            alert('Please sign the form before submitting');
            return;
        }

        const submitData = {
            inspectionDate: formData.checkDate,
            reviewedBy: formData.reviewedBy,
            reviewDate: formData.reviewDate,
            items: formData.items,
            signature: signature
        };

        onSave(submitData);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen={isMobile}
        >
            <DialogTitle>
                {mode === 'edit' && editingCheck ? `Edit Check #${editingCheck.id}` : 'New Pre-Operational Cleaning Check'}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <TextField
                            label="Inspection Date"
                            type="date"
                            value={formData.checkDate}
                            onChange={(e) => handleFormChange('checkDate', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                    </Grid>

                    {/* Render different item views based on screen size */}
                    <Grid item xs={12}>
                        {isMobile ? (
                            <MobileItemsView
                                equipmentItems={EQUIPMENT_ITEMS}
                                formData={formData}
                                onRadioChange={handleRadioChange}
                                onTextChange={handleTextChange}
                            />
                        ) : (
                            <DesktopItemsView
                                equipmentItems={EQUIPMENT_ITEMS}
                                formData={formData}
                                onRadioChange={handleRadioChange}
                                onTextChange={handleTextChange}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                            <b>Reviewer:</b> {user.given_name} {user.facility_name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Review Date"
                            type="date"
                            value={formData.reviewDate}
                            onChange={(e) => handleFormChange('reviewDate', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {/* Make sure to reset the signature when in add mode */}
                        <SignatureCanvas
                            width={isMobile ? 300 : 600}
                            height={150}
                            initialSignature={mode === 'add' ? null : signature}
                            onSignatureChange={handleSignatureChange}
                            key={mode === 'add' ? 'new-signature' : 'edit-signature'} // Force remount when adding
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    {mode === 'edit' ? 'Update' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EquipmentCheckFormDialog;