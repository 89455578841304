import React from 'react';
import * as LucideIcons from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StatusDialog from './StatusDialog';
import { Column, Item, StatusOption } from './types/tables';
import { startCase } from 'lodash';

interface StatusCellProps {
    value: number;
    statusOption: StatusOption;
    item: Item;
    column: Column;
    onUpdate?: (id: string, value: any) => void;
}

const StatusCell = ({
                        value,
                        statusOption,
                        item,
                        column,
                        onUpdate
                    }: StatusCellProps) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const IconComponent = LucideIcons[statusOption.icon as keyof typeof LucideIcons] as LucideIcon;

    if (!IconComponent) return null;

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsDialogOpen(true);
    };

    const handleClose = (event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation();
        }
        setIsDialogOpen(false);
    };

    const handleSave = (newStatus: number, notes: string, event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation();
        }
        if (onUpdate) {
            onUpdate(column.id, {
                status: newStatus,
                notes: notes
            });
        }
        handleClose();
    };

    return (
        <>
            <Tooltip title={`${startCase(statusOption.text)}${item.status_notes ? ':' : ''} ${item.status_notes}`}>
                <IconButton
                    size="small"
                    onClick={handleClick}
                    sx={{ p: 0.5 }}
                >
                    <IconComponent
                        size={20}
                        color={statusOption.color}
                        aria-label={statusOption.text}
                    />
                </IconButton>
            </Tooltip>

            <StatusDialog
                isOpen={isDialogOpen}
                onClose={handleClose}
                onSave={handleSave}
                currentName={item.name}
                currentStatus={value}
                currentNotes={item.status_notes || ''}
                options={column.typeOptions || []}
            />
        </>
    );
};

export default StatusCell;