// types.ts - Common type definitions

export interface EquipmentItem {
    id: string;
    name: string;
}

export interface EquipmentItemState {
    clean: string;
    correctiveAction: string;
    completed: string;
    operational: string;
    comments: string;
}

export interface FormDataState {
    checkDate: string;
    reviewDate: string;
    reviewedBy: string;
    items: Record<string, EquipmentItemState>;
}

export interface EquipmentCheck {
    id: number;
    inspectionDate: string;
    reviewedBy: string;
    reviewDate: string;
    items: Record<string, EquipmentItemState>;
    signature: string;
}

// Get initial form data structure
export const getInitialFormData = (equipmentItems: EquipmentItem[]): FormDataState => ({
    checkDate: '',
    reviewDate: '',
    reviewedBy: '',
    items: equipmentItems.reduce((acc, item) => {
        acc[item.id] = {
            clean: '',
            correctiveAction: '',
            completed: '',
            operational: '',
            comments: ''
        };
        return acc;
    }, {} as Record<string, EquipmentItemState>)
});

// List of standard equipment items
export const EQUIPMENT_ITEMS: EquipmentItem[] = [
    { id: 'scale', name: 'Scale' },
    { id: 'table', name: 'Table' },
    { id: 'mixer', name: 'Mixer' },
    { id: 'scoop', name: 'Scoop' },
    { id: 'floor', name: 'Floor' }
];

// Mock data for testing
export const MOCK_CHECKS_DATA: EquipmentCheck[] = [
    {
        id: 1,
        inspectionDate: '2025-03-10',
        reviewedBy: 'John Smith',
        reviewDate: '2025-03-10',
        items: {
            scale: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: 'Working well' },
            table: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            mixer: { clean: 'No', correctiveAction: 'Yes', completed: 'Yes', operational: 'Yes', comments: 'Needed cleaning' },
            scoop: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            floor: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' }
        },
        signature: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEDSURBVHhe7dIxAQAgDMAwwL/nwUZGj0TBvjMzI3CGYEEUwYIoggVRBAuiCBZEESyIIlgQRbAgimBBFMGCKIIFUQQLongPCx6wsCCKYEEUwYIoggVRBAuiCBZEESyIIlgQRbAgimBBFMGCKIIFUbzlDw9YWBBFsCCKYEEUwYIoggVRBAuiCBZEESyIIlgQRbAgimBBFMGCKN7DggcsLIgiWBBFsCCKYEEUwYIoggVRBAuiCBZEESyIIlgQRbAgimBBFG/5wwMWFkQRLIgiWBBFsCCKYEEUwYIoggVRBAuiCBZEESyIIlgQRbAginew4AEL6za7B7Tr3p/G29O3AAAAAElFTkSuQmCC"
    },
    {
        id: 2,
        inspectionDate: '2025-03-15',
        reviewedBy: 'Sarah Johnson',
        reviewDate: '2025-03-15',
        items: {
            scale: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            table: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            mixer: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            scoop: { clean: 'No', correctiveAction: 'Yes', completed: 'No', operational: 'No', comments: 'Needs replacement' },
            floor: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' }
        },
        signature: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="
    },
    {
        id: 3,
        inspectionDate: '2025-03-20',
        reviewedBy: 'Mike Davis',
        reviewDate: '2025-03-20',
        items: {
            scale: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            table: { clean: 'No', correctiveAction: 'Yes', completed: 'Yes', operational: 'Yes', comments: 'Fixed leg' },
            mixer: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            scoop: { clean: 'Yes', correctiveAction: '', completed: '', operational: 'Yes', comments: '' },
            floor: { clean: 'No', correctiveAction: 'Yes', completed: 'Yes', operational: 'Yes', comments: 'Spill cleaned' }
        },
        signature: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="
    }
];