// BatchReport.tsx - Component for displaying batch reports in a table

import React, { useEffect, useState, useMemo } from 'react';
import {
    Box, TableContainer, Paper, Table, TableHead,
    TableBody, TableRow, TableCell, TableSortLabel,
    FormControl, InputLabel, Select, MenuItem,
    SelectChangeEvent, Typography, Button
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { createAxiosWrapper } from "../Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import useUserStore from "../store/userStore";
import BatchReportDialog from './BatchReportDialog';
import { host } from '../tableConfig';

// Define the structure of a batch report item
interface BatchReportItem {
    DATE: string | null;
    PRODUCT: string;
    INGREDIENT: string;
    "INGREDIENT LOT CODE": string;
    "PRODUCT BATCH #": number;
    QUANTITY: string;
    "EXP. DATE": string | null;
    "Pre-Inspection": string;
    "Post-Inspection": string;
}

// Define sort direction type
type Order = 'asc' | 'desc';

// Define sort property type
type OrderBy = 'DATE' | 'PRODUCT BATCH #' | 'EXP. DATE';

const BatchReport: React.FC = () => {
    const [reportData, setReportData] = useState<BatchReportItem[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedClientName, setSelectedClientName] = useState<string>('');
    const { user } = useUserStore();
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    // Sorting states
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<OrderBy>('DATE');

    // Get clients from the store
    const clients = useMemo(() => user?.clients || [], [user?.clients]);

    // Determine if we're dealing with a single client
    const isSingleClient = useMemo(() => clients.length <= 1, [clients]);

    // Set initial client selection
    useEffect(() => {
        if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            setSelectedClientName(clients[0]?.name || user?.organization || '');
        }
    }, [isSingleClient, user?.clientId, clients, user?.organization]);

    const fetchReportData = async () => {
        if (!selectedClientId) return;

        try {
            const response = await axiosWrapper({url: `${host}/batch-report/${selectedClientId}`});

            // Sort the data when it arrives
            const data = response.data.data || [];
            const sortedData = sortData(data, orderBy, order);
            setReportData(sortedData);
        } catch (error) {
            console.error('Error fetching Batch Report Data:', error);
        }
    };

    useEffect(() => {
        if (selectedClientId) {
            fetchReportData();
        }
    }, [selectedClientId]);

    // Function to sort the data
    const sortData = (data: BatchReportItem[], property: OrderBy, sortOrder: Order): BatchReportItem[] => {
        const stabilizedThis = data.map((el, index) => [el, index] as [BatchReportItem, number]);

        stabilizedThis.sort((a, b) => {
            const aValue = a[0][property];
            const bValue = b[0][property];

            // For dates, convert to timestamp for comparison
            if (property === 'DATE' || property === 'EXP. DATE') {
                const aDate = aValue ? new Date(aValue).getTime() : 0;
                const bDate = bValue ? new Date(bValue).getTime() : 0;

                return sortOrder === 'desc' ? bDate - aDate : aDate - bDate;
            }

            // For batch numbers (numeric)
            if (property === 'PRODUCT BATCH #') {
                const aBatch = typeof aValue === 'number' ? aValue : 0;
                const bBatch = typeof bValue === 'number' ? bValue : 0;

                return sortOrder === 'desc' ? bBatch - aBatch : aBatch - bBatch;
            }

            // For strings
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortOrder === 'desc'
                    ? bValue.localeCompare(aValue)
                    : aValue.localeCompare(bValue);
            }

            return 0;
        });

        return stabilizedThis.map(el => el[0]);
    };

    // Handle sort request
    const handleRequestSort = (property: OrderBy) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        // Apply the sort to current data
        const sortedData = sortData([...reportData], property, newOrder);
        setReportData(sortedData);
    };

    // Open dialog to show all reports for printing
    const handleShowPrintableForm = () => {
        setOpenDialog(true);
    };

    // Handle client change from dropdown
    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };

    // Memoize the client menu items
    const clientMenuItems = useMemo(() =>
            clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    // Format date for display
    const formatDate = (dateString: string | null) => {
        if (!dateString) return '-';
        return new Date(dateString).toLocaleDateString();
    };

    return (
        <Box sx={{
            maxWidth: '600px',
            margin: '0 auto 80px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '12px',
            paddingRight: '12px'
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '20px',
                marginTop: '20px'
            }}>
                {!isSingleClient ? (
                    <FormControl sx={{
                        width: { xs: '90%', sm: '250px' },
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px'
                    }}>
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            onChange={handleClientChange}
                            fullWidth
                        >
                            {clientMenuItems}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientName && (
                        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                            Client: {selectedClientName}
                        </Typography>
                    )
                )}
            </Box>

            {selectedClientId && (
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    {/* Printable form button */}
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mb: 2
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<PrintIcon />}
                            onClick={handleShowPrintableForm}
                            sx={{ mt: 2 }}
                        >
                            Printable Form
                        </Button>
                    </Box>

                    {/* Batch reports table with sorting */}
                    <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'DATE'}
                                            direction={orderBy === 'DATE' ? order : 'asc'}
                                            onClick={() => handleRequestSort('DATE')}
                                        >
                                            Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'PRODUCT BATCH #'}
                                            direction={orderBy === 'PRODUCT BATCH #' ? order : 'asc'}
                                            onClick={() => handleRequestSort('PRODUCT BATCH #')}
                                        >
                                            Batch #
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Product</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.map((report, index) => (
                                    <TableRow
                                        key={index}
                                        hover
                                        sx={{ cursor: 'default' }}
                                    >
                                        <TableCell>{formatDate(report.DATE)}</TableCell>
                                        <TableCell>{report['PRODUCT BATCH #']}</TableCell>
                                        <TableCell>{report.PRODUCT}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}

            {/* Report dialog for printable form */}
            <BatchReportDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                report={null}
                allReports={reportData}
            />
        </Box>
    );
};

export default BatchReport;