import React from 'react';
import { Container } from '@mui/material';

export const Test: React.FC = () => {
    return (
        <Container maxWidth="md">
          <h1>TEST</h1>
        </Container>
    );
};

