import React, { useState } from 'react';
import {
    Typography,
    Box,
    Alert,
    Button,
    styled,
    Paper
} from '@mui/material';

// Define the interface for the component items
interface ComponentItem {
    name: string;
    label: string;
    component: React.ReactNode; // Add component property to store the actual component
}

// Define the props interface
interface DynamicComponentLoaderProps {
    componentsData?: ComponentItem[];
    title?: string,
}

// Create a styled component for the menu item
const MenuItemCard = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
    maxWidth: 200,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
        boxShadow: theme.shadows[3],
    },
}));

const DynamicComponentLoader: React.FC<DynamicComponentLoaderProps> = ({ componentsData = [], title }) => {
    const [activeComponent, setActiveComponent] = useState<React.ReactNode | null>(null);
    const [activeComponentName, setActiveComponentName] = useState<string | null>(null);
    const [activeComponentLabel, setActiveComponentLabel] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    // Sample data to use if no components are provided
    const sampleComponents = [
        { name: 'dashboard', label: 'Dashboard', component: <div>Dashboard Component</div> },
        { name: 'settings', label: 'Settings', component: <div>Settings Component</div> },
        { name: 'profile', label: 'User Profile', component: <div>User Profile Component</div> },
        { name: 'reports', label: 'Reports', component: <div>Reports Component</div> }
    ];

    // Use the provided componentsData, or fall back to sample data if empty
    const displayComponents = componentsData.length > 0 ? componentsData : sampleComponents;

    // Validate input data
    if (!componentsData || !Array.isArray(componentsData)) {
        return <Alert severity="error">Error: Component data must be an array</Alert>;
    }

    // Check if any items are missing required properties
    const invalidItem = displayComponents.findIndex(item => !item.name || !item.label || !item.component);
    if (invalidItem !== -1) {
        return (
            <Alert severity="error">
                Error: Item at index {invalidItem} is missing required properties (name, label, or component)
            </Alert>
        );
    }

    const loadComponent = (componentName: string, componentLabel: string, component: React.ReactNode) => {
        try {
            setActiveComponentName(componentName);
            setActiveComponentLabel(componentLabel);
            setActiveComponent(component);
        } catch (err) {
            // Properly handle the unknown error type
            const errorMessage = err instanceof Error ? err.message : String(err);
            setError(`Error loading component ${componentName}: ${errorMessage}`);
        }
    };

    // If a component is loaded, render that instead
    if (activeComponent) {
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ marginBottom: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setActiveComponent(null);
                            setActiveComponentName(null);
                            setActiveComponentLabel(null)
                        }}
                        size="small"
                    >
                        Back to {title} Menu
                    </Button>
                    </Box>
                    {activeComponentName && (
                        <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>
                            {activeComponentLabel}
                        </Typography>
                    )}
                </Box>

                {/* Render the actual component */}
                {activeComponent}
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 2 }}>
            {error && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                    {error}
                </Alert>
            )}

            {displayComponents.length === 0 && (
                <Typography color="textSecondary">
                    No components provided.
                </Typography>
            )}

            {displayComponents.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 200, margin: '0 auto' }}>
                    {displayComponents.map((component, index) => (
                        <MenuItemCard
                            elevation={1}
                            onClick={() => loadComponent(component.name, component.label, component.component)}
                            key={index}
                        >
                            <Typography align="center" color="textPrimary">
                                {component.label}
                            </Typography>
                        </MenuItemCard>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default DynamicComponentLoader;