// SignatureCanvas.tsx - Component for capturing signatures

import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCanvas = styled('canvas')({
    border: '1px solid #ccc',
    width: '100%',
    touchAction: 'none', // Prevents scrolling when drawing on mobile
});

interface SignatureCanvasProps {
    width: number;
    height: number;
    initialSignature?: string | null;
    onSignatureChange: (signature: string | null) => void;
}

const SignatureCanvas: React.FC<SignatureCanvasProps> = ({
                                                             width,
                                                             height,
                                                             initialSignature,
                                                             onSignatureChange
                                                         }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isDrawing, setIsDrawing] = useState(false);

    // Effect to clear and initialize the canvas when the component mounts or initialSignature changes
    useEffect(() => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Clear the canvas first
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // If there's an initial signature, draw it
        if (initialSignature) {
            const img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0);
            };
            img.src = initialSignature;
        } else {
            // If there's no initial signature, ensure canvas is blank
            // and signal that there's no signature
            onSignatureChange(null);
        }
    }, [initialSignature, canvasRef.current]);

    // Signature handlers
    const startDrawing = (e: React.MouseEvent | React.TouchEvent) => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const rect = canvas.getBoundingClientRect();

        // Handle both mouse and touch events
        const clientX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
        const clientY = 'clientY' in e ? e.clientY : e.touches[0].clientY;

        ctx.beginPath();
        ctx.moveTo(
            clientX - rect.left,
            clientY - rect.top
        );
        setIsDrawing(true);
    };

    const draw = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isDrawing || !canvasRef.current) return;

        // Prevent scrolling on touch devices
        if ('touches' in e) e.preventDefault();

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const rect = canvas.getBoundingClientRect();

        // Handle both mouse and touch events
        const clientX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
        const clientY = 'clientY' in e ? e.clientY : e.touches[0].clientY;

        ctx.lineWidth = 2;
        ctx.lineCap = 'round';
        ctx.strokeStyle = 'black';
        ctx.lineTo(
            clientX - rect.left,
            clientY - rect.top
        );
        ctx.stroke();
    };

    const stopDrawing = () => {
        if (isDrawing && canvasRef.current) {
            const signature = canvasRef.current.toDataURL();
            onSignatureChange(signature);
            setIsDrawing(false);
        }
    };

    const clearSignature = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        onSignatureChange(null);
    };

    return (
        <>
            <Typography variant="subtitle1" gutterBottom>Signature:</Typography>
            <Box sx={{ border: '1px solid #ccc', p: 1, borderRadius: 1, mb: 1 }}>
                <StyledCanvas
                    ref={canvasRef}
                    width={width}
                    height={height}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                />
            </Box>
            <Button
                variant="outlined"
                size="small"
                onClick={clearSignature}
            >
                Clear Signature
            </Button>
        </>
    );
};

export default SignatureCanvas;